import PercentileForm from "./PercentileForm.vue";
import Vue from 'vue';
import i18n from './i18n'

export function addLiftFormViewModel(serverLiftViewModel, staticUrl, strengthStandardsUrl, howToUrl, calculatorUrl, defaultExercise) {
    new (Vue.extend(PercentileForm))({
        el: '#formCalcJs',
        i18n,
        propsData: {
            serverLiftViewModel,
            staticUrl,
            strengthStandardsUrl,
            howToUrl,
            calculatorUrl,
            defaultExercise,
        }
    });

    const noJsVersion = document.getElementById('formCalc');
    noJsVersion.parentNode.removeChild(noJsVersion);
}