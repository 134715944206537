<template>
  <div class="modal" v-if="isOpen || makeHtmlReady" :class="{'is-active': isOpen}">
    <div class="modal-background" @click="hideModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title"></slot>
        </p>
        <button class="delete" aria-label="close" @click="hideModal" type="button"></button>
      </header>

      <slot name="under-header"></slot>

      <section class="modal-card-body" :class="{'is-paddingless': hideBodyPadding}">
        <slot></slot>
      </section>

      <footer class="modal-card-foot">
        <div class="buttons" v-if="!hideFooter" style="flex: 1; justify-content: space-between;">
          <slot name="footer"></slot>
          <a class="button" @click="hideModal">{{ $t('forms.modal.close') }}</a>
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    hideBodyPadding: Boolean,
    makeHtmlReady: Boolean,
    hideFooter: Boolean,
  },
  destroyed() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    showModal() {
      this.isOpen = true;
      this.$emit('show');
      document.addEventListener("keydown", this.onKeyDown);
      document.documentElement.classList.add('modal-open');
    },
    hideModal() {
      document.documentElement.classList.remove('modal-open');
      document.removeEventListener("keydown", this.onKeyDown);
      this.isOpen = false;
      this.$emit('hide');
    },
    onKeyDown({key}) {
      if (key === "Escape") {
        this.$nextTick(() => {
          if (this.isOpen) {
            this.hideModal();
          }
        });
      }
    },
  },
  data() {
    return {
      isOpen: false,
    }
  }
}
</script>