<template>
  <div class="table-container">
    <table class="table is-fullwidth is-striped is-narrow"
           :class="{ 'has-text-right': table.alignment && table.alignment === 'right', 'has-text-left': table.alignment && table.alignment === 'left' }">
      <thead>
      <tr>
        <th v-for="(header, index) in table.headers"
            :class="{ 'has-text-right': table.alignments && table.alignments[index] && table.alignments[index] === 'right' && table.alignments[index] !== (table.alignment ?? 'left'), 'has-text-left': table.alignments && table.alignments[index] && table.alignments[index] === 'left' && table.alignments[index] !== (table.alignment ?? 'left') }"
            :style="{ 'width': table.widths[index] ? (table.widths[index] + '%') : undefined }">
          <abbr v-if="table.abbreviations[index]" :title="header"><span>{{ table.abbreviations[index] }}</span></abbr>
          <template v-else>{{ header }}</template>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(row, rowIndex) in table.body">
        <td v-for="(cell, index) in row"
            :class="{ 'has-text-right': table.alignments && table.alignments[index] && table.alignments[index] === 'right' && table.alignments[index] !== (table.alignment ?? 'left'), 'has-text-left': table.alignments && table.alignments[index] && table.alignments[index] === 'left' && table.alignments[index] !== (table.alignment ?? 'left'), 'has-background-tablehighlight': hasHighlight(rowIndex, index) }">
          {{ cell }}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  props: {
    table: Object,
  },
  methods: {
    hasHighlight(rowIndex, cellIndex) {
      return _.some(this.table.highlights ?? [], x => x[0] === rowIndex && x[1] === cellIndex);
    }
  }
}
</script>