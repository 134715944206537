import _ from "lodash";
import {round} from "./round";

export const unitDefinitions = [
    {
        "symbol": "kg",
        "conversionToBaseUnit": 1,
        "step": 0.1,
        "remainderSymbol": null,
        "remainderMultiple": null
    },
    {
        "symbol": "lb",
        "conversionToBaseUnit": 0.45359237,
        "step": 0.1,
        "remainderSymbol": null,
        "remainderMultiple": null
    },
    {
        "symbol": "st",
        "conversionToBaseUnit": 0.45359237,
        "step": 0.1,
        "remainderSymbol": "lb",
        "remainderMultiple": 14
    },
    {
        "symbol": "cm",
        "conversionToBaseUnit": 1,
        "step": 0.1,
        "remainderSymbol": null,
        "remainderMultiple": null
    },
    {
        "symbol": "ft",
        "conversionToBaseUnit": 2.54,
        "step": 0.5,
        "remainderSymbol": "in",
        "remainderMultiple": 12
    }
];

export const bodyMassUnits = ['kg', 'lb', 'st'];
export const liftMassUnits = ['kg', 'lb'];

export function getUnitDefinition(globalUnit) {
    return _.find(unitDefinitions, x => x.symbol === globalUnit);
}

export function toUnitViewModel(baseUnitQuantity, symbol) {
    const unit = getUnitDefinition(symbol);

    let viewModel = {};

    viewModel.symbol = unit.symbol;
    viewModel.remainderSymbol = unit.remainderSymbol;

    viewModel.step = unit.step;

    if (baseUnitQuantity != null) {
        const unitQuantity = baseUnitQuantity / unit.conversionToBaseUnit;
        const scaledUnitQuantity = Math.round(unitQuantity / unit.step);

        if (viewModel.remainderSymbol == null) {
            viewModel.quantity = round(scaledUnitQuantity * unit.step, 2);
        } else {
            const scaledRemainderMultiple = Math.round(unit.remainderMultiple / unit.step);
            viewModel.quantity = Math.floor(scaledUnitQuantity / scaledRemainderMultiple);
            viewModel.remainderQuantity = round((scaledUnitQuantity - viewModel.quantity * scaledRemainderMultiple) * unit.step, 2);
        }
    } else {
        viewModel.quantity = '';
        viewModel.remainderQuantity = '';
    }

    return viewModel;
}