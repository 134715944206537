import Vue from "vue";
import VueI18n from "vue-i18n";
import en from './en.json';

export const defaultLocale = window.vuelang ?? 'en';

const messages = {
    en: en
};

if (!(defaultLocale in messages)) {
    if (window.sltrans && defaultLocale in window.sltrans) {
        messages[defaultLocale] = window.sltrans[defaultLocale];
    }
}

Vue.use(VueI18n);

export default new VueI18n({
    locale: defaultLocale,
    fallbackLocale: 'en',
    messages: messages
});
