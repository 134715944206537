import {round} from "./round";
import {toUnitViewModel} from "./units";

export function formatMass(massKg, unitSymbol) {
    if (typeof massKg === 'undefined' || massKg === null) {
        return '-';
    }

    const unitParts = [];

    switch (unitSymbol) {
        case 'kg':
            unitParts.push(round(massKg, 1) + ' kg');
            break;

        case 'lb':
            unitParts.push(round(massKg / 0.45359237, 1) + ' lb');
            break;

        case 'st':
            const unitViewModel = toUnitViewModel(massKg, 'st');
            if (unitViewModel.quantity > 0) {
                unitParts.push(unitViewModel.quantity + ' st');
            }
            if (unitViewModel.remainderQuantity > 0) {
                unitParts.push(unitViewModel.remainderQuantity + ' lb');
            }
            if (unitParts.length < 1) {
                unitParts.push('0 lb');
            }
            break;
    }

    return unitParts.join(' ');
}