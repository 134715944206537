export const categories = ['Barbell', 'Bodyweight', 'Dumbbell', 'Machine', 'Cable', 'Kettlebell'];
export const categoriesNoKettlebell = categories.filter(x => x !== 'Kettlebell');
export const bodyparts = ['Whole Body', 'Legs', 'Back', 'Chest', 'Shoulders', 'Biceps', 'Triceps', 'Core', 'Forearms'];
export const measurementBodyParts = ['Waist', 'Hips', 'Neck', 'Chest', 'Shoulders', 'Right Bicep', 'Left Bicep', 'Right Forearm', 'Left Forearm', 'Right Thigh', 'Left Thigh', 'Right Calf', 'Left Calf'];
export const types = ['Weight/Reps', 'Weight/Time', 'Weight/Distance', 'Bodyweight/Reps', 'Bodyweight/Time', 'Distance/Time'];


export function getRequiredFields(type)
{
    switch (type) {
        case 'Weight/Reps':
            return ['weight', 'reps'];
        case 'Weight/Time':
            return ['weight', 'time'];
        case 'Weight/Distance':
            return ['weight', 'distance'];
        case 'Bodyweight/Reps':
            return ['reps'];
        case 'Bodyweight/Time':
            return ['time'];
        case 'Distance/Time':
            return ['distance', 'time'];
        default:
            throw 'Could not find exercise type ' + type;
    }
}

export function getOptionalFields(type)
{
    switch (type) {
        case 'Weight/Reps':
            return ["rpe", "notes", "warmup", "dropset", "rest"];
        case 'Weight/Time':
            return ["notes", "warmup", "dropset", "rest"];
        case 'Weight/Distance':
            return ["notes", "warmup", "dropset", "rest"];
        case 'Bodyweight/Reps':
            return ['weight', "rpe", "notes", "warmup", "dropset", "rest"];
        case 'Bodyweight/Time':
            return ["notes", "warmup", "dropset", "rest"];
        case 'Distance/Time':
            return ["notes", "warmup", "dropset", "rest"];
        default:
            throw 'Could not find exercise type ' + type;
    }
}