import * as StrengthLevel from './coreExports';
import {yieldToMainBackground} from "./yield";


window.slrun = function () {
    const args = arguments;
    yieldToMainBackground().then(() => {
        StrengthLevel[args[0]].apply(null, [...args].slice(1));
    })
};

yieldToMainBackground().then(() => {
    StrengthLevel.setupVueAxios();
    window.slqueue.forEach(x => window.slrun.apply(null, x));
});