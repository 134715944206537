<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label for="bodymass" class="label">{{ label ?? 'Bodyweight' }}</label>
    </div>
    <div class="field-body">
      <div class="field has-addons">
        <div class="control is-expanded">
          <input type="number" id="bodymass" name="bodymass" class="input"
                 :required="required" min="5" max="400" :step="bodyMassViewModel.step"
                 v-model="quantity">
        </div>
        <div class="control">
          <span class="button is-static">
            {{ bodyMassViewModel.symbol }}
            <input type="hidden" name="bodymassunit" :value="bodyMassViewModel.symbol">
          </span>
        </div>
        <template v-if="bodyMassViewModel.symbol === 'st'">
          <div class="control is-expanded">
            <input type="number" id="bodymass_minor" name="bodymass_minor" class="input"
                   min="0" max="13.5"
                   step="0.5"
                   v-model="remainderQuantity">
          </div>
          <div class="control">
                  <span class="button is-static">
                    {{ bodyMassViewModel.remainderSymbol }}
                  </span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {formatBodyMassInput} from "./formatBodyMassInput";

export default {
  props: {
    bodyMassViewModel: Object,
    label: String,
    required: {
      type: Boolean,
      default: true,
    }
  },
  created() {
    this.resetModel(this.bodyMassViewModel);
  },
  data() {
    return {
      quantity: '',
      remainderQuantity: '',
    };
  },
  methods: {
    resetModel(bodyMassViewModel) {
      this.quantity = formatBodyMassInput(bodyMassViewModel.quantity);
      this.remainderQuantity = formatBodyMassInput(bodyMassViewModel.remainderQuantity);
    },
    getBodyweight(bodyMassUnit) {
      bodyMassUnit = bodyMassUnit ?? window.bodyMassUnit;
      switch (bodyMassUnit) {
        case 'kg':
          return parseFloat(this.quantity);
        case 'lb':
          return parseFloat(this.quantity) * 0.45359237;
        case 'st':
          let total = 0.0;

          if (this.quantity !== '') {
            const quantity = parseFloat(this.quantity);
            if (isNaN(quantity)) {
              return null;
            }
            total += quantity * 14;
          }

          if (this.remainderQuantity !== '') {
            const remainderQuantity = parseFloat(this.remainderQuantity);
            if (isNaN(remainderQuantity)) {
              return null;
            }
            total += remainderQuantity;
          }

          return total * 0.45359237;
      }
    }
  }
}
</script>