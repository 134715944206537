function normalizeForSearch(search) {
    search = search.toLowerCase();

    // Remove all spaces e.g. lat pull down, someone might enter pulldown
    search = search.replace(/\s+/g, '');

    // Dumbbell is also spelled dumbell
    search = search.replace(/dumbb/g, 'dumb');

    return search;
}

export function isExerciseMatch(search, exerciseText) {
    const normalizedSearch = normalizeForSearch(search);
    const normalizedExerciseText = normalizeForSearch(exerciseText);
    return normalizedExerciseText.indexOf(normalizedSearch) !== -1;
}