import Vue from 'vue';
import exercisemodal from './webapp/ExerciseModal.vue';
import i18n from "./i18n";

export function addStandardsExerciseSelectorViewModel(staticUrl, exercise, strengthStandardsUrl) {
    new Vue({
        el: '#exerciseSelectorForm',
        i18n,
        data(){
            const initialExercises = window.prefill?.find(x => x.request?.url === '/api/exercises')?.response ?? null;
            return {
                initialExercises,
                isComparison: false,
                exercise: exercise,
                staticUrl,
            };
        },
        components: {
            exercisemodal,
        },
        methods: {
            showExerciseModal(isComparison) {
                this.isComparison = isComparison;
                this.$refs.exerciseSelectorModal.showModal();
            },
            selectExercise(exerciseId, exercise) {
                this.$refs.exerciseSelectorModal.hideModal();
                if (this.isComparison) {
                    // Prevent comparing against oneself
                    if (this.exercise.id !== exercise.id) {
                        window.location = strengthStandardsUrl + '/' + this.exercise.name_url + '-vs-' + exercise.name_url;
                    }
                } else {
                    window.location = strengthStandardsUrl + '/' + exercise.name_url;
                }
            },
        }
    });
}