<template>
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <label for="gender" class="label">Gender</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <div class="select is-fullwidth">
            <select id="gender" name="gender" :value="value" @input="$emit('input', $event.target.value)">
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
  }
}
</script>