import _ from 'lodash';
import Vue from 'vue';
import {unitDefinitions} from "./units";
import i18n from "./i18n";

export function addPowerliftingCalculator(rawCalculatorViewModel, element) {
    new Vue({
        el: element,
        i18n,
        data: {
            unitDefinitions,
            ...rawCalculatorViewModel,
        },
        computed: {
            bodyMassUnit() {
                return _.find(this.unitDefinitions, ['symbol', this.bodyMassUnitSymbol]);
            },
            liftMassUnit() {
                return _.find(this.unitDefinitions, ['symbol', this.liftMassUnitSymbol]);
            },
        },
        watch: {
            'bodyMassUnitSymbol': function (value) {
                if (value !== 'st') {
                    this.liftMassUnitSymbol = value;
                }
            },
            'liftMassUnitSymbol': function (value) {
                if (value !== 'st') {
                    this.bodyMassUnitSymbol = value;
                }
            },
        },
    });
}