import Vue from 'vue';
import exerciseselector from './exerciseselector.vue';
import i18n from "./i18n";

export function addExerciseSelectorViewModel(exercisesTitle, baseUrl, suggestExerciseUrl, staticUrl) {
    const initialExercises = window.prefill?.find(x => x.request?.url === '/api/exercises')?.response ?? null;
    new (Vue.extend(exerciseselector))({
        el: '#exerciseSelectorContainer',
        i18n,
        propsData: {
            initialExercises: initialExercises,
            exercisesTitle: exercisesTitle,
            baseUrl: baseUrl,
            suggestExerciseUrl: suggestExerciseUrl,
            staticUrl: staticUrl,
        }
    });
}