<template>
  <Modal class="exercise-modal" ref="modal" :hide-body-padding="true" :make-html-ready="true" :hide-footer="isMobile" @show="onShow" @hide="onHide">
    <template v-slot:title>{{ $t('forms.exercisemodal.selectexercise') }}</template>
    <template v-slot:under-header>
      <section class="modal-card-search">
        <div class="field">
          <div class="control is-expanded has-icons-left" :class="{'is-loading': loadingExercises || searching}">
            <input name="modalsearch" type="text" class="input" :placeholder="$t('forms.exercisemodal.search')"
                   :aria-label="$t('forms.exercisemodal.search')"
                   @input="debounceSearch($event.target.value)" @keydown="handleKeydown" ref="modalSearch"
                   autocomplete="off">
            <span class="icon is-small is-left">
              <i class="slicon slicon-fa-magnifying-glass"></i>
            </span>
          </div>
        </div>
        <div class="field is-grouped is-grouped-multiline">
          <div class="control">
            <div class="select is-small">
              <select name="modalbodypart" autocomplete="off" v-model="bodypart" :aria-label="$t('forms.howtomodal.bodypart')">
                <option value="">{{ $t("enums.bodypart.Any Body Part") }}</option>
                <option v-for="bodypart in bodyparts" :value="bodypart">{{ $t('enums.bodypart.' + bodypart) }}</option>
              </select>
            </div>
          </div>

          <div class="control">
            <div class="select is-small">
              <select name="modalcategory" autocomplete="off" v-model="category" :aria-label="$t('forms.howtomodal.category')">
                <option value="">{{ $t("enums.category.Any Category") }}</option>
                <option v-for="category in categories" :value="category">{{ $t('enums.category.' + category) }}</option>
              </select>
            </div>
          </div>
        </div>
      </section>
    </template>
    <div v-if="!initialExercises" v-show="loadingFirstTime">
      <div v-for="index in itemsPerPage" class="media">
        <span class="media-left">
          <span class="icon is-left has-background-grey-lighter p-4" style="border-radius: 2px"></span>
        </span>
        <span class="media-content">
          <span style="display: flex">
            <span class="p-2 has-background-grey-lighter" style="flex: 1; border-radius: 2px"></span>
            <span class="tag is-small ml-1" style="width: 5rem"></span>
          </span>
          <template v-if="index % 3 == 1">
            <div class="p-2 mt-2 has-background-grey-lighter" style="width: 100%; border-radius: 2px"></div>
          </template>
        </span>
      </div>
    </div>
    <div v-if="!loadingFirstTime" ref="exerciseList"
         :style="{ minHeight: exerciseListMinHeight !== null ? exerciseListMinHeight + 'px' : undefined }">
      <a class="media" v-for="exercise in limitedExerciseList" @click="selectExercise(exercise)">
        <picture class="media-left image is-32x32">
          <source type="image/webp" :srcset="exercise.icon_url.replace('128.png', '64.webp') + ' 2x, ' + exercise.icon_url.replace('128.png', '32.webp') + ' 1x'">
          <img loading="lazy" :src="exercise.icon_url.replace('128.png', '32.png')" :srcset="exercise.icon_url.replace('128.png', '64.png') + ' 2x, ' + exercise.icon_url.replace('128.png', '32.png') + ' 1x'" :alt="exercise.name" width="32" height="32">
        </picture>
        <span class="media-content">
          <WordHighlighter :query="searchText" class="mr-1">{{ exercise.name }}</WordHighlighter>
          <span class="tag is-small">{{ $t('enums.bodypart.' + exercise.bodypart) }}</span>
          <span v-if="typeof exercise.percentile !== 'undefined'" class="tag is-small"><Stars
              :percentile="exercise.percentile"></Stars></span>
          <span class="tag is-small" v-if="exercise.user_id">Custom</span>

          <template v-if="exercise.aliases.length &gt; 0">
            <br>
            <span class="has-text-black is-size-7">
              {{ $t('forms.exercisemodal.alsoknownas') }}
              <WordHighlighter :query="searchText">{{ exercise.aliases.join(', ') }}</WordHighlighter>
            </span>
          </template>
        </span>
      </a>
      <div class="media" v-if="!loadingExercises && count === 0">
        <div class="media-content">
          {{ $t('forms.exercisemodal.noexercises') }}
        </div>
      </div>
      <div class="media">
        <div class="media-content">
          <button class="button is-fullwidth-mobile" type="button" @click="increaseLimit" :disabled="!showButton" :class="{'is-loading': loadingExercises || searching}">
            {{ $t('forms.exercisemodal.moreexercises') }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import _ from "lodash";
import WordHighlighter from "vue-word-highlighter";
import Modal from "./Modal.vue";
import {isExerciseMatch} from "../isExerciseMatch";
import {bodyparts, categories, categoriesNoKettlebell} from "../exercisedata";
import Stars from "../Stars.vue";
import isMobile from "is-mobile";

const innerHeight = window.innerHeight;
const itemsPerPage = innerHeight > 900 ? 16 : (window.innerHeight > 700 ? 12 : 8);
const requestSize = 32;

export default {
  components: {
    Stars,
    WordHighlighter,
    Modal,
  },
  props: {
    staticUrl: String,
    userId: {
      type: [Number, String],
      default: null,
    },
    standard: {
      type: Boolean,
      default: null,
    },
    custom: {
      type: Boolean,
      default: null,
    },
    initialExercises: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.resetModal();
  },
  data() {
    return {
      debounceSearchingTrue: _.debounce(() => this.searching = true, 200, { leading: true, trailing: false }),
      debounceSetSearchText: _.debounce((value) => this.searchText = value, 200, { leading: true, trailing: true }),
      debounceSearchingFalse: _.debounce(() => this.searching = false, 200, { leading: false, trailing: true }),
      showing: false,
      isMobile: isMobile(),
      loadingFirstTime: true,
      loadingExercises: null,
      searching: false,
      itemsPerPage,
      count: Number.POSITIVE_INFINITY,
      bodypart: '',
      category: '',
      searchText: '',
      exercises: [],
      setSummaries: [],
      setSummaryExercises: [],
      limit: itemsPerPage,
      categories: this.standard ? categoriesNoKettlebell : categories,
      bodyparts: bodyparts,
      exerciseListMinHeight: null,
    }
  },
  methods: {
    debounceSearch(value) {
      this.debounceSearchingTrue();
      this.debounceSetSearchText(value);
      this.debounceSearchingFalse();
    },
    handleKeydown(e) {
      if (e.key === 'Enter') {
        e.preventDefault();
        const firstExercise = this.limitedExerciseList[0];
        if (firstExercise) {
          this.selectExercise(firstExercise);
        }
      }
    },
    resetModal() {
      this.limit = itemsPerPage;
      this.searchText = '';
      this.category = '';
      this.bodypart = '';

      if (this.initialExercises) {
        this.exercises = this.initialExercises.data;
        this.count = this.initialExercises.meta.count;
        this.loadingFirstTime = false;
      } else {
        this.exercises = [];
        this.count = Number.POSITIVE_INFINITY;
        this.loadingFirstTime = true;
      }
    },
    selectExercise(exercise) {
      if (this.userId) {
        this.$emit('on-select', exercise.name_url, exercise.id);
      } else {
        this.$emit('on-select', exercise.id, exercise);
      }
    },
    showModal() {
      this.showing = true;
      this.$refs.modal.showModal();

      if (!this.initialExercises) {
        this.loadSetSummaries().then(() => {
          return this.loadExercisesIfRequired();
        }).finally(() => {
          this.loadingFirstTime = false;
        });
      }
    },
    hideModal() {
      this.$refs.modal.hideModal();
    },
    onShow() {
      this.$emit('show');
      if (!this.isMobile) {
        this.$nextTick(() => this.$refs.modalSearch.focus());
      }
    },
    onHide() {
      this.showing = false;
      this.$emit('hide');
      this.$nextTick(() => this.resetModal());
    },
    updateMinHeight() {
      if (!this.exerciseListMinHeight && this.$refs.exerciseList) {
        this.exerciseListMinHeight = this.$refs.exerciseList.parentNode.offsetHeight;
      }
    },
    getFoundAliases(exercise) {
      return exercise.aliases.filter(y => isExerciseMatch(this.searchText, y));
    },
    hasFoundAlias(exercise) {
      return this.getFoundAliases(exercise).length > 0;
    },
    hasFoundExercise(exercise) {
      return isExerciseMatch(this.searchText, exercise.name);
    },
    increaseLimit() {
      this.limit += itemsPerPage;
      this.loadExercisesIfRequired(true);
    },
    resetLimit(isSubset) {
      this.limit = itemsPerPage;
      this.loadExercisesIfRequired(isSubset);
    },
    loadExercisesIfRequired(isSubset) {
      if (this.showing) {
        if (!isSubset || this.limit > this.filteredExerciseList.length) {
          return this.loadExercises();
        }
      }
      return Promise.resolve();
    },
    createParams() {
      const limit = Math.ceil(this.limit / requestSize) * requestSize;

      let params = {
        limit: limit,
        'exercise.fields': 'category,name_url,bodypart,count,aliases,icon_url',
      };

      if (this.searchText !== '') {
        params.query = this.searchText;
      }
      if (this.category !== '') {
        params.category = this.category;
      }
      if (this.bodypart !== '') {
        params.bodypart = this.bodypart;
      }
      if (this.standard !== null) {
        params.standard = this.standard ? 'true' : 'false';
      }
      if (this.custom !== null) {
        params.custom = this.custom ? 'true' : 'false';
      }
      if (this.userId !== null) {
        params.user_id = this.userId;
      }
      return params;
    },
    loadExercises() {
      const params = this.createParams();
      this.loadingExercises = params;

      return this.$http.get('/exercises', {
        params: params,
        cache: true,
      }).then(response => {
        const updatedParams = this.createParams();
        if (_.isEqual(params, updatedParams)) { // Filter out multiple requests
          this.exercises = response.data.data;
          this.count = response.data.meta.count;
        }
      }).finally(() => {
        if (this.loadingExercises === params) {
          this.loadingExercises = null;
        }
      });
    },
    loadSetSummaries() {
      if (!this.userId) {
        return Promise.resolve();
      }

      return this.$http
          .get('/set-summaries', {
            params: {
              user_id: this.userId,
              'setsummary.fields': 'percentile,exercise_id,latest_set_date',
              'exercise.fields': 'category,name_url,bodypart,count,aliases,icon_url',
              'trainingarchive': 'true',
              'period': 'all',
              'expansions': 'setsummary.exercise_id',
            }
          })
          .then(response => {
            this.setSummaries = response.data.data;
            this.setSummaryExercises = response.data.includes.exercises;
          });
    },
  },

  computed: {
    blendedExercises() {
      const setSummaryExerciseMap = _.keyBy(this.setSummaryExercises, 'id');
      const orderedSetSummaries = _.orderBy(this.setSummaries, 'latest_set_date', 'desc');
      const setSummaryExercises = orderedSetSummaries
          .filter(summary => setSummaryExerciseMap[summary.exercise_id])
          .map(summary => ({
            ...setSummaryExerciseMap[summary.exercise_id],
            percentile: summary.percentile !== null ? summary.percentile : undefined,
            date: summary.date
          }));
      const foundSetSummariesMap = _.keyBy(setSummaryExercises, 'id');
      const moreExercises = this.exercises.filter(exercise => !foundSetSummariesMap[exercise.id]);
      return setSummaryExercises.concat(moreExercises);
    },
    filteredExerciseList() {
      let exercises = this.blendedExercises;
      if (this.category !== '') {
        exercises = exercises.filter(x => x.category === this.category);
      }
      if (this.bodypart !== '') {
        exercises = exercises.filter(x => x.bodypart === this.bodypart);
      }
      if (this.searchText !== '') {
        exercises = exercises.filter(x => this.hasFoundExercise(x) || this.hasFoundAlias(x));
      }
      return exercises;
    },
    limitedExerciseList() {
      return this.filteredExerciseList.slice(0, this.limit);
    },
    eventCategory() {
      return this.userId ? 'user-exercise-modal' : 'calculator-exercise-modal';
    },
    showButton() {
      return this.count > this.limit || this.filteredExerciseList.length > this.limit;
    },
  },

  watch: {
    searchText(value, oldValue) {
      this.updateMinHeight();
      this.resetLimit(oldValue === '' || value.indexOf(oldValue) === 0);
    },
    category(value, oldValue) {
      this.updateMinHeight();
      this.resetLimit(oldValue === '');
      if (value) {
        gtag('event', this.userId ? 'filter-category' : 'filter-group', {
          'event_category': this.eventCategory,
          'event_label': value
        });
      }
    },
    bodypart(value, oldValue) {
      this.updateMinHeight();
      this.resetLimit(oldValue === '');
      if (value) {
        gtag('event', 'filter-body-part', {'event_category': this.eventCategory, 'event_label': value});
      }
    },
  },
};
</script>