<template>
  <Modal ref="modal" @hide="$emit('input', null)" :make-html-ready="true">
    <template v-slot:title>{{ $t('forms.howtomodal.howto') }}</template>

    <template v-if="exercise">
      <h3 class="title is-4 is-size-5-mobile mb-3">{{ exercise.name }}</h3>

      <div class="field is-grouped is-grouped-multiline mb-1">
        <div class="control">
          <div class="tags has-addons">
            <span class="tag">
              {{ $t('forms.howtomodal.bodypart') }}
            </span>
            <span class="tag is-primary">
              {{ $t('enums.bodypart.' + exercise.bodypart) }}
            </span>
          </div>
        </div>
        <div class="control">
          <div class="tags has-addons">
            <span class="tag">
              {{ $t('forms.howtomodal.category') }}
            </span>
            <span class="tag is-primary">
              {{ $t('enums.category.' + exercise.category) }}
            </span>
          </div>
        </div>
      </div>

      <div class="columns is-mobile is-multiline">
        <div class="column is-half" v-for="(image_url, sequenceIndex) in exercise.images">
          <h3 class="subtitle is-5 mb-1">
            {{ sequenceIndex + 1 }}
          </h3>
          <picture class="image is-square">
            <source media="(min-width: 769px)" type="image/avif" :srcset="image_url.replace('800.jpg', '800.avif')">
            <source type="image/avif" :srcset="image_url.replace('800.jpg', '400.avif')">
            <source media="(min-width: 769px)" :srcset="image_url.replace('800.jpg', '800.jpg')">
            <img loading="lazy" :src="image_url.replace('800.jpg', '400.jpg')" :alt="exercise.name + ' - Image ' + (sequenceIndex + 1)">
          </picture>
        </div>
      </div>
    </template>
    <template v-else>
      <h3 class="title is-4 is-size-5-mobile mb-3">
        <span class="p-3 has-background-grey-lighter" style="display: inline-block; width: 6em; border-radius: 2px"></span>
      </h3>

      <div class="field is-grouped is-grouped-multiline mb-1">
        <div class="control">
          <div class="tags has-addons">
            <span class="tag" style="width: 4rem"></span>
            <span class="tag" style="width: 4rem"></span>
          </div>
        </div>
        <div class="control">
          <div class="tags has-addons">
            <span class="tag" style="width: 4rem"></span>
            <span class="tag" style="width: 4rem"></span>
          </div>
        </div>
      </div>

      <div class="columns is-mobile is-multiline">
        <div class="column is-half" v-for="index in 2">
          <h3 class="subtitle is-5 mb-1">
            <span class="p-3 has-background-grey-lighter" style="display: inline-block; width: 3em; border-radius: 2px"></span>
          </h3>
          <figure class="image is-square has-background-grey-lighter" style="border-radius: 2px"></figure>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from "./Modal.vue";

export default {
  components: {Modal},
  props: {
    value: [Number, String],
  },
  data() {
    return {
      exercise: null,
    }
  },
  mounted() {
    this.updateHowTo();
  },
  watch: {
    value() {
      this.updateHowTo();
    }
  },
  methods: {
    updateHowTo() {
      if (!this.value) {
        this.$refs.modal.hideModal();
        return;
      }

      this.$refs.modal.showModal();
      if (!this.exercise || this.exercise.id !== this.value) {
        this.exercise = null;
        this.loadExercise();
      }
    },
    loadExercise() {
      return this.$http.get('/exercises/' + this.value, {
        params: {
          'exercise.fields': 'category,name_url,bodypart,images',
          'lang': window.vuelang,
        },
        cache: true,
      }).then(response => {
        this.exercise = response.data.data;
      });
    },
  },
}
</script>