// See https://web.dev/case-studies/pubconsent-inp?hl=en
export function yieldToMainBackground() {
    return new Promise((resolve) => {
        if ('scheduler' in window) {
            // Progressive enhancement so opting for background priority if possible
            if ('postTask' in window.scheduler) {
                return window.scheduler.postTask(() => {
                    resolve(true);
                }, {priority: 'background'});
            }

            if ('yield' in window.scheduler) {
                return window.scheduler.yield().then(() => {
                    resolve(true);
                });
            }
        }

        setTimeout(() => {
            resolve(true)
        }, 0);
    });
}
