import {nodeListToArray} from "./nodeListToArray";
import {yieldToMainBackground} from "./yield";

export function setupTabs() {
    function getLocalStorageKey(tabGroupLabel) {
        return 'Tab Group/' + tabGroupLabel;
    }

    function initTabContainer(tabContainer) {
        function setTab(activeIndex) {
            const tabListItems = nodeListToArray(tabContainer.querySelector('.tabs').querySelectorAll('li'));
            const tabsAll = nodeListToArray(tabContainer.querySelectorAll('.tab'));

            const tabs = tabsAll.filter(tab => tab.parentNode === tabContainer);

            tabListItems.forEach((tabListItem, index) => {
                const isActive = index === activeIndex;

                tabListItem.classList.toggle('is-active', isActive);
                if (tabs[index]) {
                    tabs[index].classList.toggle('is-hidden', !isActive);
                }
            });
        }

        const tabGroupLabel = tabContainer.getAttribute('data-tab-group');
        const localStorageKey = getLocalStorageKey(tabGroupLabel);

        tabContainer.setAttribute("data-tabsetup", 'yes'); // Only setup once

        const tabListItems = nodeListToArray(tabContainer.querySelector('.tabs').querySelectorAll('li'));
        const tabLabels = [];
        let defaultActiveIndex = 0;

        tabListItems.forEach((tabListItem, tabListIndex) => {
            const tabLabel = tabListItem.getAttribute('data-tab');
            tabLabels.push(tabLabel);

            if (tabListItem.classList.contains('is-active')) {
                defaultActiveIndex = tabListIndex;
            }

            const tabAnchor = tabListItem.querySelector('a');
            tabAnchor.addEventListener('click', function () {
                setTab(tabListIndex);

                yieldToMainBackground().then(() => {
                    try {
                        localStorage.setItem(localStorageKey, tabLabel);
                    } catch (e) {
                        // Expected condition so not reporting, sometimes local storage is off
                        console.warn('Could not store tab state: ' + localStorageKey);
                    }

                    if (typeof gtag === 'function') {
                        gtag('event', 'tab-action', {
                            'event_category': 'switch-tab',
                            'event_label': tabGroupLabel + '/' + tabLabel
                        });
                    }
                })
            });
        });

        try {
            const activeTabLabel = localStorage.getItem(localStorageKey);
            if (activeTabLabel !== null) {
                let localStorageActiveIndex = tabLabels.indexOf(activeTabLabel);
                if (localStorageActiveIndex !== -1 && localStorageActiveIndex !== defaultActiveIndex) {
                    setTab(localStorageActiveIndex);
                }
            }
        } catch (e) {
            // Expected condition so not reporting, sometimes local storage is off
            console.warn('Could not get tab state: ' + localStorageKey);
        }
    }

    const tabContainers = nodeListToArray(document.querySelectorAll('.tabs-container:not([data-tabsetup="yes"])'));
    tabContainers.forEach(initTabContainer);
}
