// const axiosCached = wrapper(axios);
// Vue.use(VueAxios, axiosCached);

import _ from "lodash";

export default function wrapper(axios, initialCache, clearCacheOnLoad) {
    let cache = initialCache || [];
    const clearCacheRequestTypes = ['delete', 'head', 'options', 'post', 'put', 'patch'];

    function axiosWithCache(...arg) {
        if (arg.length === 1 && (arg[0].method === 'get' || arg[0].method === undefined)) {
            return getWithCache(arg[0], axios, ...arg)
        }
        return axios(...arg);
    }

    function getWithCache(option, params) {
        const cacheEntry = _.find(cache, (x) => x.request.url === '/api' + option && _.isEqual(x.request.params, params));
        if (cacheEntry) {
            return Promise.resolve({
                __cached: true,
                data: cacheEntry.response,
            })
        }
        return null;
    }

    axiosWithCache.get = function (...arg) {
        const url = arg[0];
        const params = arg[1] ? arg[1].params : {};
        const withCache = getWithCache(arg[0], params);
        if (withCache) {
            return withCache.then(function (response) {
                //console.info('Request was cached: ', url, params, response.data);
                return response;
            });
        } else {
            return axios.get(...arg).then(function (response) {
                //console.warn('Request could be cached: ', url, params, response.data);
                if (arg[1] && arg[1].cache) {
                    cache.push({
                        request: {
                            url: '/api' + url,
                            params: params,
                        },
                        response: response.data,
                    });
                }
                return response;
            });
        }
    }

    function clearCache() {
        // Try to avoid clearing /exercises
        cache = cache.filter(x => x.request.url.indexOf('/exercises') !== -1);
    }

    function breadcrumb(method, arg) {
        window.Sentry && Sentry.addBreadcrumb({
            category: "request",
            message: method + " request",
            level: "debug",
            data: arg,
        });
    }

    if (clearCacheOnLoad) {
        window.addEventListener('load', () => clearCache());
    }

    clearCacheRequestTypes.forEach(method => {
        axiosWithCache[method] = function (...arg) {
            breadcrumb(method, arg);
            clearCache();
            return axios[method](...arg);
        }
    });

    for (const method in axios) {
        if (axios.hasOwnProperty(method) && typeof axios[method] === 'function' && !(method in axiosWithCache)) {
            axiosWithCache[method] = function (...arg) {
                breadcrumb(method, arg);
                return axios[method](...arg);
            }
        }
    }

    return axiosWithCache
}