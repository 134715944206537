import Vue from 'vue'

let pluginOptions = {};

const modals = new Map();

function show(ModalComponent, props = {}) {
    let modalInstance = modals.get(ModalComponent);

    if (!modalInstance) {
        const BaseConstructor = Vue.extend(pluginOptions);
        const ModalConstructor = BaseConstructor.extend(ModalComponent);
        modalInstance = new ModalConstructor({
            propsData: props,
        })
        modalInstance.$mount()
        document.body.appendChild(modalInstance.$el)
        modals.set(ModalComponent, modalInstance)
    } else {
        Object.assign(modalInstance.$props || modalInstance.$options.propsData, props)
    }

    return modalInstance;
}

export const modalManager = { show }

export function vueModalPlugin(app, options = {}) {
    pluginOptions = options;  // Store the options

    const modalKey = '$modal'
    const vueVersion = Number(app.version.split('.')[0])

    if (vueVersion === 2) {
        Object.defineProperty(app.prototype, modalKey, {
            get() {
                return modalManager
            }
        })
        app[modalKey] = modalManager
    } else if (vueVersion === 3) {
        app.config.globalProperties[modalKey] = modalManager
        app.provide(modalKey, modalManager)
    }
}