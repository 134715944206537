import Vue from 'vue';
import StandardsCreate from "./StandardsCreate.vue";
import i18n from "./i18n";

export function addStandardsCreateViewModel(elementId, gender, exerciseId, exerciseName, exerciseCategory, unit) {
    new Vue({
        components: {standardscreate:StandardsCreate},
        el: '#' + elementId,
        i18n,
        data() {
            return {
                gender: gender,
                exerciseId: exerciseId,
                exerciseName: exerciseName,
                exerciseCategory: exerciseCategory,
                unit: unit,
            };
        },
        methods: {
        }
    });
}