export function addCollapsibleSection(elementTitle, elementSection, onChange, isOpenByDefault) {
    onChange = onChange || (() => undefined);

    let isOpen = isOpenByDefault || false;

    function setOpen(isOpen) {
        elementTitleNode.classList.toggle('collapsible__title--closed', !isOpen);
        elementSelectionNode.classList.toggle('collapsible--closed', !isOpen);
    }

    const elementSelectionNode = document.getElementById(elementSection);
    const elementTitleNode = document.getElementById(elementTitle);
    elementTitleNode.classList.add('collapsible__title');
    setOpen(isOpen);

    elementTitleNode.addEventListener('click', function() {
        const isOpening = elementSelectionNode.classList.contains(
            'collapsible--closed');
        setOpen(isOpening);
        onChange(isOpening);
    });
}
