import wrapper from "./webapp/axiosCache";
import axios from "axios";
import Vue from "vue";
import {vueAxiosPlugin} from "./vueAxiosPlugin";

export function setupVueAxios() {
    const axiosCached = wrapper(axios, window.prefill);
    Vue.use(vueAxiosPlugin, axiosCached);

    axios.defaults.baseURL = window.apiUrl;
}